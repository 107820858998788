import React from 'react';
import intl from 'react-intl-universal';

interface ILoadingProps {
  timedOut?: boolean;
  error?: string;
  pastDelay?: boolean;
  retry?: any;
}

const Loading = ({
  timedOut = false,
  error = '',
  pastDelay = false,
  retry = null
}: ILoadingProps) => {
  const style = {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '300px'
  };

  if (timedOut || error) {
    return (
      <div style={style}>
        {intl.get('timeout')}
        <button type="button" onClick={retry} style={{ color: 'black' }}>
          {intl.get('retry')}
        </button>
      </div>
    );
  }

  if (pastDelay) {
    return <div style={style}>Loading...</div>;
  }

  return null;
};

export default Loading;
