import cookie from 'js-cookie';
import qs from 'qs';
import intl from 'react-intl-universal';

const SUPPORT_LOCALES = [
  {
    name: 'English',
    value: 'en-US'
  }
];

const loadLocales = (callback) => {
  let currentLocale = 'en-US';
  currentLocale = intl.determineLocale({
    urlLocaleKey: 'lang',
    cookieLocaleKey: 'lang'
  });

  if (!SUPPORT_LOCALES.find((item) => item.value === currentLocale)) {
    currentLocale = 'en-US';
  }

  const query = qs.parse(window.location.href.split('?')[1]);
  if (query && query.lang) {
    cookie.set('lang', query.lang, { expires: 1000 });
  }

  import(`../locales/${currentLocale}.json`)
    .then((m) => {
      const options = {
        currentLocale,
        locales: {
          [currentLocale]: m
        },
        fallbackLocale: 'en-US',
        commonLocaleDataUrls: {}
      };
      intl.init(options);
    })
    .then(() => {
      if (callback) {
        callback(currentLocale);
      }
    });
};

export { SUPPORT_LOCALES, loadLocales };
