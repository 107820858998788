/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation
} from 'react-router-dom';
import renderRouter from './routers';
import { AppProvider } from './context/AppContext';
import mainRouters from './routers/config';
import { isMobile } from './utils/helpers';
import { loadLang } from './utils/decorators';
import Header from './components/Header';
import Footer from './components/Footer';

import 'antd/dist/antd.css';
import './styles/global.scss';

import { VoiceContext } from './utils/effects';

interface IAppProps {
  currentLocale: string | null;
  location: any;
}

interface IAppState {
  voice: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  togggleVoice: () => {};
}

class App extends React.Component<any, any> {
  constructor(props) {
    super(props);

    // State 也包含了更新函数，因此它会被传递进 context provider。
    this.state = {
      voice: true,
      toggleVoice: this.toggleVoice
    };

    this.toggleVoice = this.toggleVoice.bind(this);
  }

  toggleVoice = () => {
    const tmp = this.state.voice;
    this.setState({
      voice: !tmp
    });
  };

  render() {
    return (
      <Router basename={process.env.REACT_APP_BASENAME}>
        <Header />
        <Switch>
          {renderRouter(mainRouters)}
          <Redirect to="/" />
        </Switch>
        <Footer />
      </Router>
    );
  }
}

export default loadLang(App);
