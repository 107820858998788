/* eslint-disable jsx-a11y/no-redundant-roles */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import './index.scss';

const EventEmitter = require('events');
const ee = new EventEmitter();
const eventName = 'videoVoice';
let buttonClass;

export const Video = () => {
  const ref = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const handleVoiceToggle = (voice) => {
      if (ref && ref.current) {
        ref.current.muted = !voice;
      }
    };

    ee.on(eventName, handleVoiceToggle);
    return () => {
      ee.removeListener(eventName, handleVoiceToggle);
    };
  }, []);

  const VideoMemo = useMemo(() => {
    return (
      <video loop muted autoPlay id="kuaishou-video" ref={ref}>
        <source
          src="https://static.yximgs.com/udata/pkg/EE-PA-PUBLIC/english-website/home-video.v1.mp4"
          type="video/mp4"
        />
        Sorry, your browser does not support embedded video.
      </video>
    );
  }, []);

  return <>{VideoMemo}</>;
};

export const VideoVoiceButton = (isHomePage) => {
  const [voice, setVoice] = useState(false);

  const handleClick = useCallback(() => {
    setVoice((preVoice) => !preVoice);
  }, []);

  const checkButtonClass = () => {
    if (isHomePage) {
      if (voice) {
        buttonClass = 'volumn-voiced';
      } else buttonClass = 'volumn-muted';
    } else buttonClass = 'li-delete';
    return buttonClass;
  };

  useEffect(() => {
    ee.emit(eventName, voice);
  }, [voice]);

  return (
    <button
      role="button"
      aria-label="Mute volume"
      onClick={handleClick}
      type="button"
      className={checkButtonClass()}
    />
  );
};
