/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { useState, useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import { useLocation, NavLink, Link } from 'react-router-dom';
import { AppConsumer } from '../../context/AppContext';
import { isMobile, setLocale, isUrl } from '../../utils/helpers';
import logo from '../../assets/logo@2x.png';
import logoblack from '../../assets/logo-black@2x.png';
import voice from '../../assets/voice.png';
import mute from '../../assets/muted.png';
import { VoiceContext } from '../../utils/effects';
import { VideoVoiceButton } from '../Video';

import './index.scss';

const PCHeader = ({ path }) => {
  const [isScroll, setIsScroll] = useState(false);
  const [isVoice, setIsVoice] = useState(false);
  const [isAboutUs, setIsAboutUs] = useState(false);
  const [isHeaderScrollChange, setIsHeaderScrollChange] = useState(false);
  // save traffic
  const onScroll = () => {
    const scrollTop = window.pageYOffset; //
    if (scrollTop > 0) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };
  // listen scroll's change
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onAboutUs = () => {
    if (path === '/') {
      setIsAboutUs(true);
    } else {
      setIsAboutUs(false);
    }
  };

  useEffect(() => {
    onAboutUs();
  });

  const onHeaderScrollChange = () => {
    if (isScroll) return setIsHeaderScrollChange(false);

    const specialPages = ['/', '/csr'];
    if (!specialPages.includes(path)) return setIsHeaderScrollChange(false);
    return setIsHeaderScrollChange(true);
  };

  useEffect(() => {
    onHeaderScrollChange();
  });

  const viewButton = () => {
    if (isAboutUs) {
      return (
        <li className="on-selected-without-hover">
          <VideoVoiceButton isHomePage={isAboutUs} />
        </li>
      );
    }
    return <></>;
  };

  return (
    <header>
      <div className={isHeaderScrollChange ? 'header' : 'scroll-header'}>
        <div className="logo">
          <img
            src={
              isHeaderScrollChange ? (isScroll ? logoblack : logo) : logoblack
            }
            alt="kuaishou"
          />
        </div>
        <ul className="menu">
          <li className="on-selected-with-hover">
            <NavLink
              exact
              to="/"
              className="on-selcted-underscore"
              activeClassName="choose-with-arrows"
            >
              {intl.get('header.about-us')}
            </NavLink>
            <ul className="sub-menu">
              <li>
                <Link to="/#company-overview" target="_self">
                  {intl.get('about-us.company-overview.title')}
                </Link>
              </li>
              <li>
                <Link to="/#culture" target="_self">
                  {intl.get('about-us.culture-and-values.title')}
                </Link>
              </li>
              <li>
                <Link to="/#leadership" target="_self">
                  {intl.get('about-us.leadership.title')}
                </Link>
              </li>
              <li>
                <Link to="/#milestones" target="_self">
                  {intl.get('about-us.milestones.title')}
                </Link>
              </li>
            </ul>
          </li>
          <li className="on-selected-with-hover">
            <NavLink
              to="/products"
              className="on-selcted-underscore"
              activeClassName="choose-with-arrows"
            >
              {intl.get('header.products')}
            </NavLink>
            <ul className="sub-menu">
              <li>
                <Link to="/products#kuaishou" target="_self">
                  {intl.get('products.kuaishou.title')}
                </Link>
              </li>
              <li>
                <Link to="/products#express" target="_self">
                  {intl.get('products.kuaishou-express.title')}
                </Link>
              </li>
              <li>
                <Link to="/products#concept" target="_self">
                  {intl.get('products.kuaishou-concept.title')}
                </Link>
              </li>
              <li>
                <Link to="/products#yitian" target="_self">
                  {intl.get('products.yitian-camera.title')}
                </Link>
              </li>
              <li>
                <Link to="/products#kmovie" target="_self">
                  {intl.get('products.kmovie.title')}
                </Link>
              </li>
              <li>
                <Link to="/products#acfun" target="_self">
                  {intl.get('products.acfun.title')}
                </Link>
              </li>
              <li>
                <a href="https://www.kwai.com/">
                  {intl.get('products.kwai.title')}
                </a>
              </li>
              <li>
                <a href="https://www.snackvideo.com/">
                  {intl.get('products.snackVideo.title')}
                </a>
              </li>
            </ul>
          </li>
          <li className="on-selected-with-hover">
            <NavLink
              to="/business"
              className="on-selcted-underscore"
              activeClassName="choose-with-arrows"
            >
              {intl.get('header.business')}
            </NavLink>
            <ul className="sub-menu">
              <li>
                <Link to="/business#live" target="_self">
                  {intl.get('business.livestreaming.title')}
                </Link>
              </li>
              <li>
                <Link to="/business#mone" target="_self">
                  {intl.get('business.online-marketing-services.title')}
                </Link>
              </li>
              <li>
                <Link to="/business#commerce" target="_self">
                  {intl.get('business.e-commerce.title')}
                </Link>
              </li>
              <li>
                <Link to="/business#game" target="_self">
                  {intl.get('business.online-games.title')}
                </Link>
              </li>
              <li>
                <Link to="/business#knowledge" target="_self">
                  {intl.get('business.Knowledge-sharing-content.title')}
                </Link>
              </li>
              <li>
                <a href="https://www.kwai.com/business">
                  {intl.get('business.kwai.title')}
                </a>
              </li>
              <li>
                <a href="https://www.snackvideo.com/business">
                  {intl.get('business.snackVideo.title')}
                </a>
              </li>
            </ul>
          </li>
          <li className="on-selected-without-hover">
            <NavLink
              to="/csr"
              className="on-selcted-without-underscore"
              activeClassName="choose-without-arrows"
              target="_blank"
            >
              {intl.get('header.cooperate-social-responsibility')}
            </NavLink>
          </li>
          <li className="on-selected-without-hover">
            <NavLink
              to="/prsee"
              className="on-selcted-without-underscore"
              activeClassName="choose-without-arrows"
            >
              {intl.get('header.prsee')}
            </NavLink>
          </li>
          <li className="on-selected-without-hover">
            <a
              role="button"
              href="https://ir.kuaishou.com"
              className="on-selcted-without-underscore"
            >
              Investors
            </a>
          </li>
          <li className="on-selected-without-hover">
            <NavLink
              to="/contact-us"
              className="on-selcted-without-underscore"
              activeClassName="choose-without-arrows"
            >
              {intl.get('header.contact-us')}
            </NavLink>
          </li>

          <li className="on-selected-without-hover">
            <a
              role="button"
              tabIndex={0}
              href="https://www.kuaishou.com/"
              className="on-selcted-without-underscore"
            >
              中文网站
            </a>
          </li>
          {/* {viewButton()} */}
        </ul>
      </div>
    </header>
  );
};

const Header = ({ contextData }) => {
  const { currentLocale } = contextData;
  const isCN = currentLocale === 'zh-CN';
  const location = useLocation();
  const { pathname: path } = location;
  console.log('new service');

  return isMobile() ? <PCHeader path={path} /> : <PCHeader path={path} />;
};

export default AppConsumer(Header);
