// 下划线转驼峰
export const camelCase = (str) =>
  str.replace(/(-|_)+(.)?/g, (_match, _p1, p2) => (p2 ? p2.toUpperCase() : ''));

// 驼峰转下划线
export const underscoreCase = (str) =>
  str.replace(/([A-Z])/g, (_match, p1) => (p1 ? `_${p1.toLowerCase()}` : ''));

// 驼峰&下划线互转
const switchCase = (fn) => (obj) => {
  if (obj instanceof Array) {
    return obj.map((item) => switchCase(fn)(item));
  }

  if (obj instanceof Object) {
    const obj1: Record<string, unknown> = {};
    Object.keys(obj).forEach((key) => {
      obj1[fn(key)] = switchCase(fn)(obj[key]);
    });
    return obj1;
  }

  return obj;
};

// 将 json key 中的下划线转为驼峰
export const camelJson = switchCase(camelCase);

// 将 json key 中的驼峰转为下划线
export const underscoreJson = switchCase(underscoreCase);

// 移除图片base64后的前缀
export const removeBase64Prefix = (str) => str.replace(/.*base64,/, '');

/**
 * 从对象中拷贝指定字段
 * @param obj 源对象
 * @param fields 指定字段列表
 */
export const copyObjectByFields = (
  obj: Record<string, unknown>,
  fields: string[]
) => {
  if (!obj) {
    return {};
  }

  const tmp = {};
  for (const field of fields) {
    tmp[field] = obj[field];
  }

  return tmp;
};

/**
 * 延迟 t 豪秒后执行某个函数
 * 用法：delay(3000).then(() => console.log('Hello'))
 * @param t
 */
export const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

export const isCNMode = (locale: string) => locale === 'zh-CN';

export const setLocale = (lang) => {
  window.location.search = `?lang=${lang}`;
};

// judge whether mobile end or not
export const isMobile = () => {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  );
};

export const isUrl = () => {
  const env = process.env.REACT_APP_BASENAME;
  let basename;
  if (env === '/') {
    basename = '/';
  }
  if (env === '/en') {
    basename = '/en/';
  }
  if (env === '/en-website') {
    basename = '/en-website/';
  }
  return basename;
};
