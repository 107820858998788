import React from 'react';
import ReactDOM from 'react-dom';
import { Weblog } from '@ks/weblogger'
import App from './App';

const logConfig = {
    fps: false,
    timing: false,
    radar: {
        projectId: '7af974753c', // 雷达平台上的唯一标识
        sampling: 0.9, // 上报采样率，1 为全部上报
    },
};
// eslint-disable-next-line no-new
new Weblog(logConfig);
ReactDOM.render(<App />, document.getElementById('root'));
