import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Loadable from './loadable';

function hashLinkScroll() {
  const { hash } = window.location;
  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }, 0);
  }
}

export default function renderRouter(config) {
  // function onVoice() {
  //   const element = document.getElementById('kuaishou-video');
  //   if (isVoice) {
  //     element.play();
  //   } else {
  //     element.onpause();
  //   }
  // }

  return Object.entries(config).map((item: any) => {
    const props: any = {
      exact: true,
      path: item[0],
      onUpdate: { hashLinkScroll }
    };

    if (item[1] instanceof Object) {
      if (item[1].exact === false) {
        props.exact = false;
      }

      if (item[1].component) {
        props.component = Loadable(item[1].component);
      }

      if (item[1].redirect) {
        props.render = () => <Redirect to={item[1].redirect} />;
      }
    } else {
      props.component = Loadable(item[1]);
    }

    return <Route {...props} key={props.path} />;
  });
}

// export * from './config';
