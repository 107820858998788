/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, Children } from 'react';
import { init } from '@rematch/core';

const Context = createContext({
  contextData: {
    currentLocale: 'en-US',
    isMuteVideo: true
  },
  updateContext: (data) => {}
});

interface IAppProviderProps {
  initContextData: any;
}

export class AppProvider extends React.Component<IAppProviderProps> {
  initContextData: any;

  constructor(props) {
    super(props);
    // const { initContextData } = this.props;
    this.initContextData = {
      currentLocale: 'en-US',
      isMuteVideo: true,
      ...this.initContextData
    };
    this.state = {};
  }

  updateContext = (newContext) => {
    this.setState(newContext);
  };

  render() {
    const { children } = this.props;
    return (
      <Context.Provider
        value={{
          contextData: { ...this.initContextData, ...this.state },
          updateContext: this.updateContext
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}

export const AppConsumer = (Component) => (props) => (
  <Context.Consumer>
    {(context) => (
      <Component
        contextData={context.contextData}
        updateContext={context.updateContext}
        {...props}
      />
    )}
  </Context.Consumer>
);
