import React from 'react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { isMobile, isUrl } from '../../utils/helpers';

import './index.scss';

const basename = isUrl();

const PCFooter = () => {
  return (
    <footer>
      <div className="footer-contact">
        <ul className="contact-info">
          <li className="baseli">
            <ul className="baseul">
              <li className="li-without-disc">
                <h2>{intl.get('footer.about-us.title')}</h2>
              </li>
              <li>
                <Link to="/#company-overview" target="_self">
                  {intl.get('footer.about-us.company-overview')}
                </Link>
              </li>
              <li>
                <Link to="/#culture" target="_self">
                  {intl.get('footer.about-us.culture-and-values')}
                </Link>
              </li>
              <li>
                <Link to="/#leadership" target="_self">
                  {intl.get('footer.about-us.leadership')}
                </Link>
              </li>
              <li>
                <Link to="/#milestones" target="_self">
                  {intl.get('footer.about-us.milestones')}
                </Link>
              </li>
            </ul>
          </li>
          <li className="baseli">
            <ul className="baseul">
              <li className="li-without-disc">
                <h2>{intl.get('footer.products.title')}</h2>
              </li>
              <li>
                <Link to="/products/#kuaishou" target="_self">
                  {intl.get('footer.products.kuaishou')}
                </Link>
              </li>
              <li>
                <Link to="/products/#express" target="_self">
                  {intl.get('footer.products.kuaishou-express')}
                </Link>
              </li>
              <li>
                <Link to="/products/#concept" target="_self">
                  {intl.get('footer.products.kuaishou-concept')}
                </Link>
              </li>
              <li>
                <Link to="/products/#yitian" target="_self">
                  {intl.get('footer.products.yitian-camera')}
                </Link>
              </li>
              <li>
                <Link to="/products/#kmovie" target="_self">
                  {intl.get('footer.products.kmovie')}
                </Link>
              </li>
              <li>
                <Link to="/products/#acfun" target="_self">
                  {intl.get('footer.products.acfun')}
                </Link>
              </li>
              <li>
                <a href="https://www.kwai.com/">
                  {intl.get('footer.products.kwai')}
                </a>
              </li>
              <li>
                <a href="https://www.snackvideo.com/ ">
                  {intl.get('footer.products.snackVideo')}
                </a>
              </li>
            </ul>
          </li>
          <li className="baseli">
            <ul className="baseul">
              <li className="li-without-disc">
                <h2>{intl.get('footer.business.title')}</h2>
              </li>
              <li>
                <Link to="/business/#live" target="_self">
                  {intl.get('footer.business.livestreaming')}
                </Link>
              </li>
              <li>
                <Link to="/business/#mone" target="_self">
                  {intl.get('footer.business.online')}
                </Link>
              </li>
              <li>
                <Link to="/business/#commerce" target="_self">
                  {intl.get('footer.business.e-commerce')}
                </Link>
              </li>
              <li>
                <Link to="/business/#game" target="_self">
                  {intl.get('footer.business.game')}
                </Link>
              </li>
              <li>
                <Link to="/business/#knowledge" target="_self">
                  {intl.get('footer.business.knowledge')}
                </Link>
              </li>
              <li>
                <a href="https://www.kwai.com/business">
                  {intl.get('footer.business.kwai')}
                </a>
              </li>
              <li>
                <a href="https://www.snackvideo.com/business">
                  {intl.get('footer.business.snackVideo')}
                </a>
              </li>
            </ul>
          </li>
          <li>
            <ul className="cooperate-social-responsibility">
              <li className="li-without-disc">
                <h2>{intl.get('footer.cooperate-social-responsibility')}</h2>
              </li>
              <li className="li-without-disc">
                <h2>{intl.get('footer.prsee')}</h2>
              </li>
              <li className="li-without-disc">
                <h2 className="join">{intl.get('footer.join-us.title')}</h2>
              </li>
              <li className="join-us">
                <a href="https://zhaopin.kuaishou.cn/recruit/e/#/official/index/">
                  {intl.get('footer.join-us.url')}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="footer-info">{intl.get('footer.record-info.info')}<a href="https://beian.miit.gov.cn/#/Integrated/index" rel="noreferrer" target="_blank">{intl.get('footer.record-info.icp')}</a><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802038443" rel="noreferrer" target="_blank" className="record-info">{intl.get('footer.record-info.record')}</a></div>
    </footer>
  );
};

const Footer = () => {
  return isMobile() ? <PCFooter /> : <PCFooter />;
};

export default Footer;
