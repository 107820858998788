import React, { PureComponent, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import LazyLoad from 'react-lazyload';
import { loadLocales } from './locale';
import Loading from '../components/Loading';

/**
 * 下载语言包，并重新加载被装饰的组件
 * @param Component
 * @returns {Wrapper}
 */
export const loadLang = (Component) => {
  class Wrapper extends PureComponent<any, any> {
    constructor(props: any) {
      super(props);
      // const { loading } = this.props;
      this.state = {
        loading: true,
        currentLocale: 'en-US'
      };
    }

    componentDidMount() {
      loadLocales((currentLocale) => {
        this.setState({
          loading: false,
          currentLocale
        });
        document.title = intl.get('app');
      });
    }

    render() {
      const { loading } = this.state;
      const { currentLocale } = this.state;
      return loading ? (
        <Loading />
      ) : (
        <Component {...this.props} currentLocale={currentLocale} />
      );
    }
  }

  return Wrapper;
};

export const AnchorTop = ({ anchorId, top = '-120px' }) => {
  return (
    <div
      id={anchorId}
      style={{
        position: 'absolute',
        top,
        height: '0px',
        width: '0px'
      }}
    >
      &nbsp;
    </div>
  );
};

export const LazyComponent = (height, innerHTML) => {
  return (
    <LazyLoad height={height} once>
      {innerHTML}
    </LazyLoad>
  );
};
